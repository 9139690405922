import axios from 'axios';
import _ from 'underscore';
import constants from '../../../globals/constants'
import store from "../../../store";

const axiosConfig = {
    baseURL: '/api/v1/'
};

const HTTP = axios.create(axiosConfig);

/**
 * Helper to set loading / error state in vuejx store and catch errors.
 * @param fn function to execute
 * @returns {Promise<*>}
 */
export const execAsyncTask = async (fn) => {
    try {
        store.commit('siteSchemaEditor/setLoading', true)
        const result = await fn();
        return result
    } catch (error) {
        console.log(error)
        store.commit('siteSchemaEditor/setError', true)
    } finally {
        store.commit('siteSchemaEditor/setLoading', false)
    }
};

export default {
    async loadInitialState({ commit }, { siteSchemaId, allowEdit }) {
        const modes = allowEdit ? [constants.MODE_VIEW, constants.MODE_EDIT] : [constants.MODE_VIEW]

        commit('setModes', modes)

        const initialData = await execAsyncTask(async () => {
            let siteSchemaResponse = await HTTP.get(`site_schemas/${siteSchemaId}.json`)
            let things = await HTTP.get(`things.json?site_id=${siteSchemaResponse.data.site_id}`)

            return {
                siteSchema: siteSchemaResponse.data,
                things: things.data
            }
        })

        const scale = localStorage.getItem(`${initialData.siteSchema.id}-scale`)
        if (scale) {
            commit('setScale', JSON.parse(scale))
        }

        commit('setActiveSiteSchema', initialData.siteSchema)
        commit('setThings', initialData.things)
    },

    async loadSiteSchemaAnnotations({ commit, state }) {
        const annotationsResponse = await execAsyncTask(() => {
            return HTTP.get((`site_schemas/${state.activeSiteSchema.id}/annotations.json`))
        })

        commit('setSiteSchemaAnnotations', annotationsResponse.data)
    },

    async loadActiveThingValues({ commit }, thing) {
        const thingValuesResponse = await execAsyncTask(() => {
            return HTTP.get(`things/${thing.id}/thing_values_with_info.json`)
        })

        commit('setThingValues', thingValuesResponse.data)
        commit('setActiveThing', thing)
    },

    async createSiteSchemaAnnotation({ commit, state }, thingValue) {
        const values = Array.isArray(thingValue.value) ? thingValue.value : [null]

        for (let idx = 0; idx < values.length; idx++) {
            const annotation = {
                left: state.position.x / state.scale.scaleX,
                top: ((state.position.y + constants.SITE_SCHEMA_EDITOR_ICON_SIZE.height + 2) / state.scale.scaleY) +
                    (idx * constants.SITE_SCHEMA_FONT_SIZE * 1.3),
                source_id: thingValue.thing_id,
                source_type: 'Thing',
                key: thingValue.key,
                value_index: idx
            }

            const createSiteSchemaAnnotationResponse = await execAsyncTask(() => {
                return HTTP.post(`site_schemas/${state.activeSiteSchema.id}/annotations.json`, { site_schema_annotation: annotation })
            })

            commit('addSiteSchemaAnnotation', createSiteSchemaAnnotationResponse.data)
        }
    },

    async deleteSiteSchemaAnnotation({ commit, state }, annotation) {
        await execAsyncTask(() => {
            return HTTP.delete(`site_schemas/${state.activeSiteSchema.id}/annotations/${annotation.id}.json`)
        })

        commit('deleteSiteSchemaAnnotation', annotation)
    },

    async updateSiteSchemaAnnotationFromFabricJSObject({ commit, state }, fabricJsObject) {
        const annotation = fabricJsObject.annotation

        _.extend(annotation, _.pick(fabricJsObject, 'left', 'top', 'angle'))
        annotation.left = annotation.left / state.scale.scaleX
        annotation.top = annotation.top / state.scale.scaleY
        annotation.scale_x = fabricJsObject.scaleX
        annotation.scale_y = fabricJsObject.scaleY

        await execAsyncTask(() => {
            return HTTP.put(`site_schemas/${state.activeSiteSchema.id}/annotations/${annotation.id}.json`, { site_schema_annotation: annotation })
        })
    },

    async updateSiteSchemaAnnotation({ commit, state }, annotation) {
        await execAsyncTask(() => {
            return HTTP.put(`site_schemas/${state.activeSiteSchema.id}/annotations/${annotation.id}.json`, { site_schema_annotation: annotation })
        })

        commit('setRedrawAnnotations', true)
    },

    setActiveMode({ commit }, mode) {
        commit('setActiveSiteSchemaAnnotation', null)
        commit('setPosition', null)
        commit('setActiveMode', mode)
    },

    setActiveSiteSchemaAnnotation({ commit }, annotation) {
        commit('setPosition', null)
        commit('setActiveSiteSchemaAnnotation', annotation)
    },

    setPosition({ commit }, position) {
        commit('setActiveSiteSchemaAnnotation', null)
        commit('setPosition', position)
    },

    setScale({ commit, state }, scale) {
        localStorage.setItem(`${state.activeSiteSchema.id}-scale`, JSON.stringify(scale));

        commit('setScale', scale)
        commit('setRedrawAnnotations', true)
    }
}
