import _ from 'underscore';
import constants from "../../../globals/constants";

export default {
    // for now we exclude groups
    getThingsBySearchTerm: (state) => {
        return (idea) => _.sortBy(
            _.filter(
                state.things, (thing) => thing.entity_type != 'GROUP' && thing.name.toLowerCase().includes(idea.toLowerCase())
            ),
            (thing) => {
                return thing.name.toLowerCase()
            })
    },

    getThingBySiteSchemaAnnotation: (state) => {
        return (thing_id) => {
            return _.find(
                state.things, (thing) => {
                    return thing.id == thing_id
                })
        }
    },

    getSiteSchemaAnnotationsByKeyAndSourceId: (state) => {
        return (key, sourceId) => {
            return _.filter(
                state.siteSchemaAnnotations, (annotation) => {
                    return annotation.key == key && annotation.source_id == sourceId
                })
        }
    },

    activeSiteSchemaAnnotation: (state) => {
        if (state.activeSiteSchemaAnnotationId === null) {
            return null;
        } else {
            return _.find(state.siteSchemaAnnotations, (annotation) => {
                return annotation.id == state.activeSiteSchemaAnnotationId
            })
        }
    },

    activeThing: (state) => {
        if (state.activeThingId === null) {
            return null;
        } else {
            return _.find(state.things, (thing) => {
                return thing.id == state.activeThingId
            })
        }
    },

    getThingValuesWithLabel: (state) => {
        return _.sortBy(_.filter(state.thingValues, (thingValue) => thingValue.label !== null), thingValue => thingValue.label)
    },

    isEditMode: (state) => {
        return state.activeMode == constants.MODE_EDIT
    },

    isEditingAllowed: (state) => {
        return state.modes.includes(constants.MODE_EDIT)
    }
}
