import axios from 'axios';

const HTTP = axios.create({
    baseURL: '/api/v1/'
});

export default {
    // request site info and status
    async requestSite({commit}, siteId) {
        return HTTP.get(`sites/${siteId}.json?include_status=true`).then(resp => commit('setSite', resp.data))
    },

    // request manufacturers and all device model version meta data
    async requestManufacturersAndDeviceModelVersions({commit}) {
        return HTTP.get(`manufacturers/device_model_versions.json`).then(resp => commit('setManufacturersAndDeviceModels', resp.data))
    },

    // request manufacturers and all product model version meta data
    async requestManufacturersAndProductModelVersions({commit}) {
        return HTTP.get(`manufacturers/product_model_versions.json`).then(resp => commit('setManufacturersAndProductModels', resp.data))
    },

    // request manufacturers and all extension model version meta data
    async requestManufacturersAndExtensionModelVersions({commit}) {
        return HTTP.get(`manufacturers/extension_model_versions.json`).then(resp => commit('setManufacturersAndExtensionModels', resp.data))
    },

    //request current applied configurations on edge-device
    async requestAndSaveLinkConfigurations({commit}, edgeDeviceId) {
        return HTTP.get(`edge-devices/${edgeDeviceId}/configurations`).then(resp => commit('setCurrentLinkConfigurations', resp.data))
    },

    // request all PVs of site
    async requestPVs({commit, state}) {
        return HTTP.get(`things.json?thing_type=PV&site_id=${state.site.id}`).then(resp => commit('setPVs', resp.data))
    },

    // request supported product ids (models / versions)
    async requestSupportedProducts({commit, state}) {
        return HTTP.get(`sites/${state.site.id}/configuration/products`)
            .then(resp => commit('setSupportedProductsIds', resp.data))
    },

    // request meta data for a specific device model or device model version
    // identified by a product id
    requestProductMetaData({commit, state}, productId) {
        commit('startLoading')

        return HTTP.get(`sites/${state.site.id}/configuration/product?product_id=${productId}`)
            .then(resp => commit('setProductMetaData', resp.data))
            .finally(commit('stopLoading'))
    },

    // request product config for a specific device specification
    async requestDeviceSpecificationConfig({commit, state}, {deviceTemplateId, configId}) {
        commit('startLoading')

        return HTTP.get(`sites/${state.site.id}/configuration/product?product_id=${configId}`)
            .then(resp => commit('setDeviceTemplateThings', { deviceTemplateId, configId, data: resp.data }))
            .finally(commit('stopLoading'))
    },

    // request a given site config or create a new one.
    async requestSiteConfig({commit, state}, siteId) {
        commit('startLoading')

        return HTTP.get(`sites/${siteId}/configuration`)
            .then(resp => {
                console.log(`Did load existing configuration for site`, resp.data)
                if (resp.data.warning) {
                    commit('addMessage', resp.data.warning)
                    commit('setSiteConfig', resp.data.configuration)
                    commit('setGeneratedSiteConfig', resp.data.configuration.data)
                } else {
                    commit('setSiteConfig', resp.data)
                    commit('setGeneratedSiteConfig', resp.data.data)
                }
            })
            .catch(e => {
                console.log(`No configuration file exists for site`, e.response)
                commit('setSiteConfig', {})
            })
            .finally(commit('stopLoading'))
    },

    // request the config update pending state
    async requestPendingUpdateState({commit, state}, siteId) {
        commit('startLoading')

        return HTTP.get(`sites/${siteId}/configuration/update_pending`)
            .then(resp => {
                commit('setConfigurationUpdatePending', resp.data)
            })
            .finally(commit('stopLoading'))
    },

    // save complete site settings and generate the config
    async saveAndGenerateSiteConfig({commit, dispatch, state, getters}, simulation) {
        commit('resetErrors')
        commit('resetMessages')
        commit('startGenerating')
        commit('setConfigurationUpdatePending', false)
        if (!getters.isEmsEnableable) {
            commit('setEmsData', {})
        }
        commit('cleanUpGridMeters')

        const payload = {
            configuration: {
                devices: state.plannedDevices,
                things: state.plannedThings,
                products: state.plannedProducts,
                residualThings: state.residualThings,
                extensions: state.plannedExtensions,
                ems: state.emsData,
                simulated: simulation
            }
        }

        console.log(`Sending configuration to navi for site ${state.site.id}`, payload)

        HTTP.put(`sites/${state.site.id}/configuration`, payload)
            .then(resp => {
                console.log(`Got generated config for site ${state.site.id}`, resp.data)
                commit('setGeneratedSiteConfig', resp.data.configuration.data)
                commit('confirmSiteConfigChanges')
                return resp.data
            })
            .then( data => {
                if (data.warning) {
                    commit('addMessage', data.warning)
                } else {
                    commit('setGenerationSuccess')
                }

                if (/installer/.test(location.search) && /redirect_url/.test(location.search)) {
                    const params = new URLSearchParams(location.search)
                    let appUrl = new URL(params.get("redirect_url"))
                    appUrl.searchParams.append('site_id', state.site.id)
                    window.location.replace(appUrl)
                }
            })
            .catch(e => {
                const details = e.response?.data?.error
                commit('addError', details)
                commit('setGenerationError')
            })
            .finally(_ => {
                commit('stopLoading')
                commit('stopGenerating')
            })
    },

    async editProductConfig({dispatch, commit, state}, product) {
      for (const device of product.devices) {
        if (!(device.deviceTemplateId in state.deviceTemplateThings)) {
          await dispatch('requestDeviceSpecificationConfig', {
            deviceTemplateId: device.deviceTemplateId,
            configId: device.configId
          })
        }
      }

      commit('startProductConfig', product)
    },

    async requestAndSetupSite({commit, dispatch, state}, { siteId }) {
        commit('startSiteSetup')
        await dispatch('requestManufacturersAndDeviceModelVersions')
        await dispatch('requestManufacturersAndProductModelVersions')
        await dispatch('requestManufacturersAndExtensionModelVersions')
        await dispatch('requestSite', siteId)
        await dispatch('requestSupportedProducts')
        await dispatch('requestSiteConfig', siteId)
        await dispatch('requestPendingUpdateState', siteId)
        commit('stopSiteSetup')
    }
}
