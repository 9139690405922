// /* eslint no-console: 0 */
import Vue from 'vue'
import focus from 'directives/focus'
import TurbolinksAdapter from 'vue-turbolinks'
import helpers from "../globals/helpers";
import VueUnits from 'vue-units';

// import store
import store from "../store";

// import custom components
const TimelineChart = () => import(/* webpackChunkName: "timeline-chart" */ '../vue/timeline-chart');
const AutarkyChart = () => import(/* webpackChunkName: "autarky-chart" */  '../vue/autarky-chart');
const AlarmsChart = () => import(/* webpackChunkName: "alarms-chart" */  '../vue/alarms-chart');
const EnergyFlowChart = () => import(/* webpackChunkName: "energy-flow-chart" */  '../vue/energy-flow-chart');
const PowerChart = () => import(/* webpackChunkName: "power-chart" */  '../vue/power-chart');
const PowerBarChart = () => import(/* webpackChunkName: "power-bar-chart" */  '../vue/power-bar-chart');
const EnergyFlowGraph = () => import(/* webpackChunkName: "energy-flow-graph" */  '../vue/energy-flow-graph');
const PowerMapChart = () => import(/* webpackChunkName: "power-map-chart" */  '../vue/power-map-chart');
const DataExport = () => import(/* webpackChunkName: "data-export" */  '../vue/data-export');
const AlarmIconBadge = () => import(/* webpackChunkName: "alarm-icon-badge" */  '../vue/alarm-icon-badge');
const ThingInfo = () => import(/* webpackChunkName: "thing-info" */  '../vue/thing-info');
const EnergyReportChart = () => import(/* webpackChunkName: "energy-report" */  '../vue/energy-report-chart');
const ProductionAndConsumptionChart = () => import(/* webpackChunkName: "production-and-consumption" */  '../vue/production-and-consumption-chart');
const EnergyAggregates = () => import(/* webpackChunkName: "energy-aggregates" */  '../vue/energy-aggregates');
const EnergyDetailReport = () => import(/* webpackChunkName: "energy-detail-report" */  '../vue/energy-detail-report');
const SiteSchemaEditor = () => import(/* webpackChunkName: "site-schema-editor" */  '../vue/site-schema-editor/site-schema-editor');
const ThingEvents = () => import(/* webpackChunkName: "site-schema-editor" */  '../vue/thing-events');
const InvestmentCalculator = () => import('../vue/investment-calculator/investment-calculator')
const RoofArea = () => import('../vue/investment-calculator/roof-area')
const SiteConfigurationTool = () => import('../vue/site-configuration-tool/SiteConfigurationTool')
const AlarmOverview = () => import('../vue/alarm-overview')
const DeviceOverview = () => import('../vue/device-overview')
const EnergyFlow = () => import('../vue/energy-flow')
const GatewayIconBadge = () => import('../vue/gateway-icon-badge')
const ThingControls = () => import('../vue/thing-controls/thing-controls')
const MeterReadings = () => import('../vue/meter_readings')
const DateRangePicker = () => import('../vue/date-range-picker')
const MeteringPointsConfigurator = () => import ('../vue/metering-points-configurator/metering-points-configurator')

// register components to use
Vue.component('timeline-chart', TimelineChart)
Vue.component('autarky-chart', AutarkyChart)
Vue.component('alarms-chart', AlarmsChart)
Vue.component('energy-flow-chart', EnergyFlowChart)
Vue.component('power-chart', PowerChart)
Vue.component('power-bar-chart', PowerBarChart)
Vue.component('energy-flow-graph', EnergyFlowGraph)
Vue.component('power-map-chart', PowerMapChart)
Vue.component('data-export', DataExport)
Vue.component('alarm-icon-badge', AlarmIconBadge)
Vue.component('thing-info', ThingInfo)
Vue.component('energy-report-chart', EnergyReportChart)
Vue.component('production-and-consumption-chart', ProductionAndConsumptionChart)
Vue.component('energy-aggregates', EnergyAggregates)
Vue.component('energy-detail-report', EnergyDetailReport)
Vue.component('site-schema-editor', SiteSchemaEditor)
Vue.component('thing-events', ThingEvents)
Vue.component('InvestmentCalculator', InvestmentCalculator)
Vue.component('roofArea', RoofArea)
Vue.component('site-configuration-tool', SiteConfigurationTool)
Vue.component('alarm-overview', AlarmOverview)
Vue.component('device-overview', DeviceOverview)
Vue.component('energy-flow', EnergyFlow)
Vue.component('gateway-icon-badge', GatewayIconBadge)
Vue.component('thing-controls', ThingControls)
Vue.component('meter-readings', MeterReadings)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('metering-points-configurator', MeteringPointsConfigurator)

// add directives
Vue.directive('focus', focus)

Vue.use(TurbolinksAdapter)
Vue.use(VueUnits)

// add global mixins
Vue.mixin({
    methods: {
        t: (scope, options = {}) => helpers.t(scope, options),
        lv: (enValue, deValue) => helpers.localizedValue(enValue, deValue),
        hideTooltips() {
            const shownTooltip = document.querySelector(".tooltip");
            if(shownTooltip != null) {
                shownTooltip.remove();
            }
        }
    },
    beforeUpdate() {
        this.hideTooltips()
    }
})

document.addEventListener('turbolinks:load', () => {

    // mount vue to a central element and
    // handle all component tags automatically
    const app = new Vue({
        el: '[data-behaviour="vue"]',
        store
    })

})
