import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import SiteSchemaEditorModule from '../vue/site-schema-editor/vuex/store';
import SiteConfigurationToolModule from '../vue/site-configuration-tool/vuex/store';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        siteSchemaEditor: SiteSchemaEditorModule,
        siteConfigurationTool: SiteConfigurationToolModule
    }
});

export default store;
