export default {
    setActiveSiteSchema(state, siteSchema) {
        state.activeSiteSchema = siteSchema
    },

    setActiveThing(state, thing) {
        state.activeThingId = thing === null ? null : thing.id
    },

    setActiveSiteSchemaAnnotation(state, annotation) {
        state.activeSiteSchemaAnnotationId = annotation === null ? null : annotation.id
    },

    setSiteSchemaAnnotations(state, annotations) {
        state.siteSchemaAnnotations = annotations
    },

    setThings(state, things) {
        state.things = things
    },

    setThingValues(state, thingValues) {
        state.thingValues = thingValues
    },

    setLoading(state, isLoading) {
        state.isLoading = isLoading
    },

    setError(state, isError) {
        state.isError = isError
    },

    setPosition(state, position) {
        state.position = position
    },

    setActiveMode(state, mode) {
        state.activeMode = mode
    },

    addSiteSchemaAnnotation(state, annotation) {
        state.siteSchemaAnnotations = [
            ...state.siteSchemaAnnotations,
            annotation
        ]
    },

    deleteSiteSchemaAnnotation(state, annotation) {
        if (state.activeSiteSchemaAnnotationId === annotation.id) {
            state.activeSiteSchemaAnnotationId = null
        }

        state.siteSchemaAnnotations = [...state.siteSchemaAnnotations.filter(a => a.id !== annotation.id)]
    },

    setRedrawAnnotations(state, redrawAnnotations) {
        state.isRedrawAnnotations = redrawAnnotations
    },

    setModes(state, modes) {
        state.modes = modes
    },

    setScale(state, scale) {
        state.scale = scale
    }
}