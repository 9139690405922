import {caseInsensitiveContains, caseInsensitiveCompare} from '../../../globals/helpers'
import constants from './../../../globals/constants'
import _ from "underscore";

const genericManufacturerId = 'a46d3817-a8a8-47e4-8dbf-8e3704702642'

export default {

    siteName: (state) => {
        return state.site?.name
    },

    siteElectricityPrice: (state) => {
        return state.site?.electricity_price
    },

    siteFeedInTariff: (state) => {
        return state.site?.feed_in_tariff
    },

    siteCurrency: (state) => {
        return state.site?.currency
    },

    isGatewayOnline: (_state, getters) => {
        return getters.gatewayState === 'online'
    },

    gatewayState: (state) => {
        return state.site && state.site.status ? state.site.status.gateways_online_state : null
    },

    hasErrors: (state) => {
        return state.errors.length !== 0
    },

    hasMessages: (state) => {
        return state.messages.length !== 0
    },

    editingLegacyDeviceModel: (state) => {
        return state.editingDevice && !state.editingDevice.hasOwnProperty('deviceTemplates')
    },

    supportedThings: (state, getters) => {
        if (getters.editingLegacyDeviceModel) {
            return state.deviceThings
        } else if (state.deviceTemplateThings) {
            return _.flatten(_.values(state.deviceTemplateThings))
        } else {
            return []
        }
    },

    supportedEmsCapabilities: (state) => {
        return state.emsCapabilities
    },

    supportedSlaveSpecifications: (state) => {
        return state.slaveSpecifications
    },

    slaveOnlyDevices: (state) => {
        return state.slaveOnlyDevices
    },

    emsControlledThings: (_state, getters) => {
        return getters.thingsByThingType(constants.EMS_CONTROLLED_THING_TYPES)
    },

    isEmsEnableable: (_state, getters) => {
        return getters.isEntitled && getters.hasGridMeter && getters.hasEmsControlledThings
    },

    gridMeters: (_state, getters) => {
        const meters = getters.thingsByThingType(constants.METER_TYPES)

        const filterCondition = meter => {
            if (getters.siteCanAddProducts) {
                return meter.thingMetadata['meterUsageType'] === 'grid'
            }
            else { // legacy thing
                return meter.typeSpecificInformation[meter.deviceIds[0]].meterUsageType === 'grid'
            }
        }

        return meters.filter(filterCondition)
    },

    isEntitled: (state) => {
        return true
    },

    hasGridMeter: (_state, getters) => {
        return getters.gridMeters.length > 0
    },

    hasConsumer: (_state, getters) => {
        return getters.thingsByThingType(constants.THING_TYPE.CONSUMER).length > 0
    },

    hasEmsControlledThings: (_state, getters) => {
        return getters.emsControlledThings.length > 0
    },

    plannedProductThings: (state) => {
        return state.plannedProducts.flatMap(product => {
            return product.devices.flatMap(device => {
                return device.things
            })
        })
    },

    plannedProductDevices: (state) => {
        return state.plannedProducts.flatMap(product => {
            return product.devices
        })
    },

    thingsByThingType: (state, getters) => (types) => {
        let compareFct;
        if (Array.isArray(types)) {
            compareFct = caseInsensitiveContains
        } else {
            compareFct = caseInsensitiveCompare
        }

        return state.plannedThings.concat(getters.plannedProductThings).filter(t => compareFct(types, t.type) && !t._removed)
    },

    productIdForDeviceModelVersionId: (state) => (id) => {
        if (!state.deviceModels || !state.deviceModelVersions || !state.supportedProductIds) {
            return null
        }

        // if the device model version itself is a product
        if (state.supportedProductIds.includes(id)) {
            return id
        }

        // else check all supported device models and return the id
        // of the device model itself
        const deviceModelId = state.deviceModelVersions[id]?.device_model_id
        if (deviceModelId && state.deviceModels[deviceModelId]) {
            return deviceModelId
        }

        return null
    },

    specificationPresent: (state) => (id) => {
        return state.protocolDefaults[id] !== undefined
    },

    productForExtension: (state, getters) => (extension) => {
        for (const productModel of getters.supportedProductModels) {
            if (!productModel.is_extension) {
                for (const productModelVersion of productModel.product_model_versions) {
                    for (const deviceTemplate of productModelVersion.device_templates) {
                        for (const deviceSpecification of deviceTemplate.device_specifications) {
                            if (deviceSpecification.config_id == extension.productReferenceUuid) {
                                return {
                                    productModel: state.productModels[productModel.id],
                                    productModelVersion: state.productModelVersions[productModelVersion.id],
                                    deviceTemplate: deviceTemplate,
                                    deviceSpecification: deviceSpecification
                                }
                            }
                        }
                    }
                }
            }
        }

        return null
    },

    supportedDeviceModels: function (state) {
        if (!state.deviceModels || !state.supportedProductIds) {
            return []
        }

        let supportedDeviceModels = []
        const deviceModelIds = Object.keys(state.deviceModels)
        deviceModelIds.forEach(key => {
            if (state.supportedProductIds.includes(key)) {
                supportedDeviceModels.push(state.deviceModels[key])
            }
        })

        return supportedDeviceModels
    },

    supportedProductModels: function (state) {
        return _.filter(Object.values(state.productModels), (productModel) => {
            return state.supportedProductIds.includes(productModel.id)
        })
    },

    extensionManufacturers: function (state, getters) {
        if (getters.siteCanAddProducts) {
            const manufacturerIds = getters.extensionProductModels.map(model => model.manufacturer_id)
            return _.filter(Object.values(getters.supportedManufacturers), (manufacturer) => {
                return manufacturerIds.includes(manufacturer.id)
            })
        } else {
            return state.extensionManufacturers
        }
    },

    extensionModels: function (state, getters) {
        if (getters.siteCanAddProducts) {
            return getters.extensionProductModels
        } else {
            return state.extensionModels
        }
    },

    extensionModelVersions: function (state, getters) {
        if (getters.siteCanAddProducts) {
            const productModelIds = getters.extensionProductModels.map(model => model.id)
            return _.filter(Object.values(getters.supportedProductModelVersions), (productModelVersion) => {
                return productModelIds.includes(productModelVersion.product_model_id)
            })
        } else {
            return state.extensionModelVersions
        }
    },

    extensionProductModels: function (_state, getters) {
        return _.filter(Object.values(getters.supportedProductModels), (productModel) => {
            return productModel.is_extension
        })
    },

    extensionInUse: (state) => (extension) => {
        return state.plannedProducts.some(product => {
            return product.extension?.extensionUuid === extension.extensionUuid
        })
    },

    // supported devices model versions are within the supported products list
    // or their device model is included
    supportedDeviceModelVersions: function (state) {
        if (!state.deviceModelVersions || !state.supportedProductIds) {
            return []
        }

        let supportedDeviceModelVersions = []
        const keys = Object.keys(state.deviceModelVersions)
        keys.forEach(key => {
            const deviceModelVersion = state.deviceModelVersions[key]
            const deviceModel = deviceModelVersion.deviceModel

            const publishedOrEditingDevice = deviceModelVersion.status === 'published' ||
                state.editingDevice?.deviceModelVersionUuid === deviceModelVersion.id
            
            if (publishedOrEditingDevice && (state.supportedProductIds.includes(key) || state.supportedProductIds.includes(deviceModel.id))) {
                supportedDeviceModelVersions.push(deviceModelVersion)
            }
        })

        return supportedDeviceModelVersions
    },

    supportedProductModelVersions: function (state) {
        if (!state.productModelVersions || !state.supportedProductIds) {
            return []
        }

        let supportedProductModelVersions = []
        const keys = Object.keys(state.productModelVersions)
        keys.forEach(key => {
            const productModelVersion = state.productModelVersions[key]

            if (productModelVersion.status === 'published' && (state.supportedProductIds.includes(key))) {
                supportedProductModelVersions.push(productModelVersion)
            }
        })

        return supportedProductModelVersions
    },

    siteCanAddProducts: function (state) {
        return state.site.product_models_enabled
    },

    supportedManufacturers: function(state, getter) {
        const indexById = (acc, item) => {
          acc[item.id] = item
          return acc
        }
        const productManufacturers = getter.supportedProductModelVersions.map(product => product.manufacturer)
        const manufacturersHash = _.reduce(productManufacturers, indexById, {})

        return _.sortBy(Object.values(manufacturersHash), function(manufacturer) {
            return [manufacturer.name !== 'neoom', !manufacturer.is_neoom, manufacturer.name.toLowerCase()]
        })
    },

    groupedSupportedManufacturers: function(state, getter) {
        let groupedManufacturer = {
            'neoom': {},
            'vue.site_config_tool.manufacturer_system_partner': [],
            'vue.site_config_tool.manufacturer_generic': {},
            'vue.site_config_tool.manufacturer_no_partner': []
        }
        for (const manufacturer of getter.supportedManufacturers) {
            if (manufacturer.name == 'neoom') {
                groupedManufacturer['neoom'] = manufacturer
            } else if(manufacturer.is_neoom) {
                groupedManufacturer['vue.site_config_tool.manufacturer_system_partner'].push(manufacturer)
            } else if(manufacturer.id == genericManufacturerId) {
                groupedManufacturer['vue.site_config_tool.manufacturer_generic'] = manufacturer
            } else {
                groupedManufacturer['vue.site_config_tool.manufacturer_no_partner'].push(manufacturer)
            }
        }
        return groupedManufacturer
    },

    thingsDirty: function (state) {
        for (let p of state.plannedProducts) {
            if (p._dirty) {
                return true
            }
        }
        for (let d of state.plannedDevices) {
            if (d._dirty) {
                return true
            }
        }

        for (let t of state.plannedThings) {
            if (t._dirty) {
                return true
            }
        }

        return false
    },

    extensionsDirty: function (state) {
        for (let extension of state.plannedExtensions) {
            if (extension._dirty) {
                return true
            }
        }
    },

    thingsMarkedForRemovalExists: function (state) {
        const finder = (e) => e._removed

        return state.plannedDevices.some(finder) || state.plannedThings.some(finder)
    },

    softwareDirty: function (state) {
        return !!state.emsData._dirty
    },

    listOfDevicePVs: function (_state, getters) {
        return getters.thingsByThingType(constants.THING_TYPE.PHOTOVOLTAIC)
    },

    listOfSgReadyHeatPumps: function (_state, getters) {
        let heatPumps = getters.thingsByThingType(constants.THING_TYPE.HEAT_PUMP)
        return heatPumps.filter((heatPump) => heatPump.deviceModelVersionUuid === '56af0290-f246-424e-bb1f-802a7603800a')
    },

    findPvForId: (_state, getters) => (thingUuid) => {
        return getters.listOfDevicePVs.find(t => t.thingUuid === thingUuid)
    },

    findHeatPumpForId: (_state, getters) => (thingUuid) => {
        return getters.thingsByThingType(constants.THING_TYPE.HEAT_PUMP).find(t => t.thingUuid === thingUuid)
    },

    findPvForDevice: (_state, getters) => (deviceUuid) => {
        return getters.listOfDevicePVs.find(t => t.deviceIds[0] === deviceUuid)
    },

    isSimulationActive: function (state) {
        return !!state.generatedConfig?.simulated
    },

    savedConfigThings: (state) => {
        return state.savedConfig.products.flatMap(product => {
            return product.devices.flatMap(device => {
                return device.things
            })
        }).concat(state.savedConfig.things)
    },

    residualThings: (state) => {
        return state.residualThings ?? []
    },

    isThingPersisted: (_state, getters) => (id) => {
        const savedThingIds = getters.savedConfigThings.map(thing => thing.thingUuid)
        return savedThingIds.includes(id)
    },
}
