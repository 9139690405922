import moment from "moment-es6";
import {v4 as uuid} from 'uuid';
import residentialComplexTemplate from '../configuration-templates/residential_complex.json'
import residentialHouseholdTemplate from '../configuration-templates/residential_household.json'
import publicParkingTemplate from '../configuration-templates/public_parking.json'
import companyTemplate from '../configuration-templates/company.json'
import industryTemplate from '../configuration-templates/industry.json'

export default {
    setSite(state, site) {
        state.site = site
    },

    setCurrentLinkConfigurations(state, configurations) {
        state.currentLinkConfigurations = configurations
    },

    setExperimental(state, experimental) {
        state.experimental = experimental
    },

    setManufacturersAndDeviceModels(state, manufacturers) {
        if (manufacturers.length) {
            state.manufacturers = [...state.manufacturers, ...manufacturers]
            parseAndSetDeviceModels(state, manufacturers)
        }
    },

    setManufacturersAndProductModels(state, manufacturers) {
        if (manufacturers.length) {
            state.manufacturers = [...state.manufacturers, ...manufacturers]
            parseAndSetProductModels(state, manufacturers)
        }
    },

    setManufacturersAndExtensionModels(state, manufacturers) {
        state.extensionManufacturers = [...state.extensionManufacturers, ...manufacturers]
        parseAndSetExtensionModels(state, manufacturers)
    },

    setPVs(state, pvs) {
        state.listOfPvs = pvs
    },

    setSupportedProductsIds(state, productIds) {
        state.supportedProductIds = productIds
    },

    setProductMetaData(state, data) {
        state.supportedProtocols = data.protocols
        state.deviceThings = data.things
    },

    setDeviceTemplateThings(state, { deviceTemplateId, configId, data }) {
        state.deviceTemplateThings = { ...state.deviceTemplateThings, [deviceTemplateId]: data.things }
        state.protocolDefaults = { ...state.protocolDefaults, [configId]: data.protocols }
        state.emsCapabilities = { ...state.emsCapabilities, [configId]: data.emsCapabilities }
        if (data.slaveSpecifications != undefined && data.slaveSpecifications.length > 0) {
            state.slaveSpecifications = { ...state.slaveSpecifications, [configId]: data.slaveSpecifications }
        }
        if (data.slaveDeviceOnly) {
            state.slaveOnlyDevices.push(configId)
        }
    },

    setSiteConfig(state, config) {
        if (config.locked === true) {
            state.lockedConfig = true
        }
        else {
            state.lockedConfig = false

            state.plannedDevices = config.data.devices
            state.plannedProducts = config.data.products || []
            state.plannedExtensions = config.data.extensions || []
            state.plannedThings = config.data.things
            state.residualThings = config.data.residualThings
            state.emsData = config.data.ems
            state.latestConfigTimestamp = moment(config.updated_at).toISOString()

            const copiedConfigData = JSON.parse(JSON.stringify(config.data))

            state.savedConfig.devices = [...copiedConfigData.devices]
            state.savedConfig.products = ('products' in copiedConfigData) ? [...copiedConfigData.products] : []
            state.savedConfig.things = [...copiedConfigData.things]
            state.savedConfig.cms = {...copiedConfigData.ems }
        }
    },

    setEmsData(state, emsData) {
        state.emsDate = emsData
    },

    setGeneratedSiteConfig(state, config) {
        state.generatedConfig = config
        if (config?.created_at) {
            state.latestConfigTimestamp = config.created_at
        }
    },

    setConfigurationUpdatePending(state, isPending) {
        state.configurationUpdatePending = isPending
    },

    confirmSiteConfigChanges(state) {
        const removedElements = e => !e._removed
        const clearElement = e => {
            delete e._removed
            delete e._added
            delete e._changed
            delete e._dirty
            return e
        }

        state.plannedProducts = state.plannedProducts.filter(removedElements).map(clearElement)
        state.plannedDevices = state.plannedDevices.filter(removedElements).map(clearElement)
        state.plannedThings = state.plannedThings.filter(removedElements).map(clearElement)
        state.plannedExtensions = state.plannedExtensions.filter(removedElements).map(clearElement)

        let ems = {...state.emsData}
        delete ems._removed
        delete ems._added
        delete ems._changed
        delete ems._dirty

        state.emsData = ems
    },

    addError(state, details) {
        if (details) {
            state.errors.push(details)
        }
    },

    resetErrors(state) {
        state.errors = []
    },

    cleanUpGridMeters(state) {
        for (const product of state.plannedProducts) {
            if (!product['_removed']) { continue }

            for (const device of product.devices) {
                for (const thing of device.things) {
                    if (state.emsData.chargingPowerValues) {
                        delete state.emsData.chargingPowerValues[thing.thingUuid]

                        if (Object.keys(state.emsData.chargingPowerValues).length == 0) {
                            state.emsData.isCmsActive = false
                        }
                    }
                }
            }
        }
    },

    addMessage(state, details) {
        if (details) {
            state.messages.push(details)
        }
    },

    resetMessages(state) {
        state.messages = []
    },

    startLoading(state) {
        state.loading = true
    },

    stopLoading(state) {
        state.loading = false
    },

    startSiteSetup(state) {
        state.siteSetup = true
    },

    stopSiteSetup(state) {
        state.siteSetup = false
    },

    startGenerating(state) {
        state.generationSuccess = false
        state.generating = true
    },

    stopGenerating(state) {
        state.generating = false
    },

    startDeviceConfig(state, device = null) {
        state.editingDevice = device
        state.mode = 'device-config'

        emitTrackingEvent('INSTALLER_AddDeviceInStep5')
    },

    startExtensionConfig(state, extension = null) {
        state.editingExtension = extension
        state.mode = 'extension-config'
    },

    startProductConfig(state, product = null) {
        if (product) {
          const productModelVersion = state.productModelVersions[product.productModelVersionUuid]

          state.editingProduct = {
            ...product,
            productModelVersion: productModelVersion,
            productModel: { ...productModelVersion.productModel, manufacturer: productModelVersion.manufacturer },
            manufacturer: productModelVersion.manufacturer
          }
        }

        state.mode = 'product-config'

        emitTrackingEvent('INSTALLER_AddDeviceInStep5')
    },

    cancelDeviceConfig(state) {
        state.editingDevice = null
        state.mode = 'configuration'
    },

    cancelProductConfig(state) {
        state.editingProduct = null
        state.mode = 'configuration'
    },

    cancelExtensionConfig(state) {
        state.editingExtension = null
        state.mode = 'configuration'
    },

    removeDeviceConfig(state, device) {
        state.plannedDevices.splice(plannedDevicesIndex(state, device), 1, removed_record(device))
        state.plannedThings = markThingsForRemovedDevice(state, device)
    },

    removeProductConfig(state, product) {
        state.plannedProducts.splice(plannedProductsIndex(state, product), 1, removed_record(product))
        state.plannedThings = markThingsForRemovedDevice(state, product)
    },

    removeExtensionConfig(state, extension) {
        state.plannedExtensions.splice(plannedExtensionsIndex(state, extension), 1, removed_record(extension))
    },

    undoRemoveDeviceConfig(state, device) {
        state.plannedDevices.splice(plannedDevicesIndex(state, device), 1, undo_record(device))
        state.plannedThings = unmarkThingsForRemovedDevice(state, device)
    },

    undoRemoveProductConfig(state, product) {
        state.plannedProducts.splice(plannedProductsIndex(state, product), 1, undo_record(product)) 
        state.plannedThings = unmarkThingsForRemovedDevice(state, product)
    },

    undoRemoveExtensionConfig(state, extension) {
        state.plannedExtensions.splice(plannedExtensionsIndex(state, extension), 1, undo_record(extension))
    },

    saveDeviceConfig(state, {device, things, editingDevice}) {
        const savedDevice = state.generatedConfig?.devices[device.deviceUuid]
        const deviceNotExists = !savedDevice

        if (deviceNotExists) {
            if (editingDevice) {
                const currentDeviceIndex = plannedDevicesIndex(state, editingDevice)
                state.plannedDevices.splice(currentDeviceIndex, 1, added_record(device))
            } else {
                state.plannedDevices.push(added_record(device))
            }
        } else {
            const currentDeviceIndex = plannedDevicesIndex(state, editingDevice)
            state.plannedDevices.splice(currentDeviceIndex, 1, changed_record(device))
        }

        saveThings(state, things)

        state.mode = 'configuration'
    },

    saveProductConfig(state, product) {
        const savedProduct = state.generatedConfig?.products?.find(p => p.id === product.id)

        if (!savedProduct) {
            if (state.editingProduct) {
                state.plannedProducts = replacePlannedProduct(state, added_record(product))
            } else {
                state.plannedProducts.push(added_record(product))
            }
        } else {
            state.plannedProducts = replacePlannedProduct(state, changed_record(product))
        }

        state.editingProduct = null
        state.mode = 'configuration'
    },

    saveExtensionConfig(state, {extension, editingExtension}) {
        const savedExtension = state.generatedConfig?.extensions?.[extension.extensionUuid]
        const extensionNotExists = !savedExtension

        if (extensionNotExists) {
            if (editingExtension) {
                const currentExtensionIndex = plannedExtensionsIndex(state, editingExtension)
                state.plannedExtensions.splice(currentExtensionIndex, 1, added_record(extension))
            } else {
                state.plannedExtensions.push(added_record(extension))
            }
        } else {
            const currentExtensionIndex = plannedExtensionsIndex(state, editingExtension)
            state.plannedExtensions.splice(currentExtensionIndex, 1, changed_record(extension))
        }

        state.mode = 'configuration'
    },

    // Removes a thing or the link to another device
    removePlannedThing(state, {thingUuid, deviceUuid}) {
        clearEmsReferencesToThing(state, thingUuid)

        if (state.site.product_models_enabled) {
            const thingIndex = state.plannedThings.findIndex(thing => thing.thingUuid == thingUuid)
            if (thingIndex < 0) {
                return
            }
            state.plannedThings.splice(thingIndex, 1, removed_record(state.plannedThings[thingIndex]))
        } else {
            const compare = (thing) => thing.thingUuid === thingUuid && thing.deviceIds.includes(deviceUuid)
            const thingIndex = state.plannedThings.findIndex(compare)
            if (thingIndex < 0) {
                return
            }

            const thing = state.plannedThings[thingIndex]
            if (thing.deviceIds.length === 1) { // remove the thing itself
                state.plannedThings.splice(thingIndex, 1, removed_record(thing));
            } else { // remove a link
                thing.deviceIds.splice(thing.deviceIds.indexOf(deviceUuid), 1);
                thing.typeSpecificInformation[deviceUuid] = null
                state.plannedThings.splice(thingIndex, 1, changed_record(thing));
            }
        }
    },

    removeResidualThing(state, thingUuid) {
        clearEmsReferencesToThing(state, thingUuid)

        const thingIndex = state.residualThings.findIndex(thing => thing.thingUuid == thingUuid)
        if (thingIndex < 0) {
            return
        }

        state.residualThings.splice(thingIndex, 1)
    },

    addResidualThing(state, thing) {
        if (state.residualThings == undefined) {
            state.residualThings = []
        }

        state.residualThings.push(thing)
    },

    saveEmsData(state, data) {
        state.emsData = changed_record(data)
    },

    setGenerationSuccess(state) {
        state.generationSuccess = true
    },

    setGenerationError(state) {
        state.generationSuccess = false
    },

    setSgReadyThingsData(state) {
        state.deviceThings = [
            {
                "type": "HEAT_PUMP",
                "class": "DEVICE",
                "deviceModelVersionUuid": [
                    "56af0290-f246-424e-bb1f-802a7603800a"
                ],
                "minNumber": 0,
                "maxNumber": 1,
                "typeSpecificInformation": {}
            }
        ]
    },

    applyConfigurationFromTemplate(state, template) {
        const configuration = getConfigFromTemplate(template)
        const preparedConfig = prepareConfigurationFromTemplate(configuration)

        state.plannedDevices = preparedConfig.devices
        state.plannedThings = preparedConfig.things
        state.emsData = preparedConfig.ems
    }
}

// Various state helper functions

function replacePlannedProduct(state, updatedProduct) {
    return state.plannedProducts.map(product => {
        return product.id === updatedProduct.id ? updatedProduct : product
    })
}

function changed_record(record) {
    return {
        ...record,
        _dirty: true,
        _changed: true
    }
}

function added_record(record) {
    return {
        ...record,
        _dirty: true,
        _added: true
    }
}

function removed_record(record) {
    return {
        ...record,
        _dirty: true,
        _removed: true,
    }
}

function undo_record(record) {
    delete record._removed

    if(!record._changed && !record._added) {
        delete record._dirty
    }

    return record
}

function plannedDevicesIndex(state, device) {
    const compare = (d) => d.deviceUuid === device.deviceUuid
    return state.plannedDevices.findIndex(compare)
}

function plannedProductsIndex(state, product) {
    const compare = (p) => p.id === product.id
    return state.plannedProducts.findIndex(compare)
}

function plannedExtensionsIndex(state, extension) {
    const compare = (e) => e.extensionUuid === extension.extensionUuid
    return state.plannedExtensions.findIndex(compare)
}

function parseAndSetDeviceModels(state, manufacturersWithDevices) {
    let deviceModels = {}
    let deviceModelVersions = {}

    for (let manufacturer of manufacturersWithDevices) {
        for (let deviceModel of manufacturer.device_models) {
            deviceModels[deviceModel.id] = {
                ...deviceModel,
                manufacturer
            }
            for (let deviceModelVersion of deviceModel.device_model_versions) {
                deviceModelVersions[deviceModelVersion.id] = {
                    ...deviceModelVersion,
                    deviceModel,
                    manufacturer
                }
            }
        }
    }

    state.deviceModels = deviceModels
    state.deviceModelVersions = deviceModelVersions
}

function parseAndSetProductModels(state, manufacturersWithProducts) {
    let productModels = {}
    let productModelVersions = {}

    for (let manufacturer of manufacturersWithProducts) {
        for (let productModel of manufacturer.product_models) {
            productModels[productModel.id] = {
                ...productModel,
                manufacturer
            }
            for (let productModelVersion of productModel.product_model_versions) {
                productModelVersions[productModelVersion.id] = {
                    ...productModelVersion,
                    productModel,
                    manufacturer
                }
            }
        }
    }

    state.productModels = productModels
    state.productModelVersions = productModelVersions
}

function parseAndSetExtensionModels(state, manufacturersWithExtensions) {
    let extensionModels = {}
    let extensionModelVersions = {}

    for (let manufacturer of manufacturersWithExtensions) {
        for (let extensionModel of manufacturer.device_models) {
            extensionModels[extensionModel.id] = {
                ...extensionModel,
                manufacturer
            }
            for (let extensionModelVersion of extensionModel.device_model_versions) {
                extensionModelVersions[extensionModelVersion.id] = {
                    ...extensionModelVersion,
                    extensionModel,
                    manufacturer
                }
            }
        }
    }

    state.extensionModels = extensionModels
    state.extensionModelVersions = extensionModelVersions
}

function markThingsForRemovedDevice(state, device) {
    let things = []
    const productModelEnabled = state.site.product_models_enabled

    for (let thing of state.plannedThings) {
        if (productModelEnabled) {
            things.push(removed_record(thing))
            continue
        } else {
            const containsId = thing.deviceIds?.includes(device.deviceUuid)

            // ignore thing without id
            if (!containsId) {
                things.push(thing)
                continue
            }

            // remove thing if id is the only one
            if (containsId && thing.deviceIds.length === 1) {
                things.push(removed_record(thing))
                continue
            }

            // filter ids of thing and remove the one from the device
            thing.deviceIds = thing.deviceIds.filter(id => id !== device.deviceUuid)
            things.push(changed_record(thing))
            rememberDeviceThingConnection(state, device.deviceUuid, thing.thingUuid)
        }
    }

    return things
}

function unmarkThingsForRemovedDevice(state, device) {
    let things = []
    const deviceUuid = device.deviceUuid
    const productModelEnabled = state.site.product_models_enabled

    for (let thing of state.plannedThings) {
        if (productModelEnabled) {
            things.push(undo_record(thing))
            continue
        } else {
            const containsId = thing.deviceIds?.includes(deviceUuid)

            // ignore thing without id
            if (!containsId) {
                things.push(thing)
                continue
            }

            // remove thing if id is the only one
            if (containsId && thing.deviceIds.length === 1) {
                things.push(undo_record(thing))
                continue
            }

            // add device id connection again and remove it
            // from the backup
            let backup = state.deviceThingsBackup[deviceUuid]
            if(backup && backup.includes(thing.thingUuid)){
                thing.deviceIds.push(deviceUuid)
                things.push(thing)
                backup.splice(backup.indexOf(thing.thingUuid, 1))
                state.deviceThingsBackup[deviceUuid] = backup
            }
        }
    }

    return things
}

function rememberDeviceThingConnection(state, thingUuid, deviceUuid) {
    if (!state.deviceThingsBackup[deviceUuid]) {
        state.deviceThingsBackup[deviceUuid] = [thingUuid]
    } else {
        state.deviceThingsBackup[deviceUuid].push(thingUuid)
    }
}

function saveThings(state, things) {
    things.forEach(thing => {
        const savedThing = state.generatedConfig?.things[thing.thingUuid]
        const thingExists = !!savedThing
        const thingCompare = (oldThing) => oldThing.thingUuid === thing.thingUuid;
        const currentThingIndex = state.plannedThings.findIndex(thingCompare)

        if (currentThingIndex >= 0) {
            if (thingExists) {
                state.plannedThings.splice(currentThingIndex, 1, changed_record(thing))
            } else {
                state.plannedThings.splice(currentThingIndex, 1, added_record(thing))
            }
        } else {
            state.plannedThings.push(added_record(thing))
        }
    })
}

function getConfigFromTemplate(template) {
    switch (template) {
        case 'residential_complex':
            return residentialComplexTemplate;
        case 'residential_household':
            return residentialHouseholdTemplate;
        case 'public_parking':
            return publicParkingTemplate;
        case 'company':
            return companyTemplate;
        case 'industry':
            return industryTemplate;
        default:
            return residentialHouseholdTemplate;
    }
}

function prepareConfigurationFromTemplate(config) {
    for(let device of config.devices) {
        const currentDeviceId = device.deviceUuid
        const newDeviceId = uuid()
        device.deviceUuid = newDeviceId
        device._dirty = true
        device._added = true

        for(let thing of config.things) {
            if (!thing.deviceIds.includes(currentDeviceId)) {
                continue
            }

            let index = thing.deviceIds.indexOf(currentDeviceId)
            thing.deviceIds[index] = newDeviceId

            if(Object.keys(thing.typeSpecificInformation).includes(currentDeviceId)) {
                let data = thing.typeSpecificInformation[currentDeviceId]
                delete thing.typeSpecificInformation[currentDeviceId]
                thing.typeSpecificInformation[newDeviceId] = data
            }
        }
    }

    for(let thing of config.things) {
        const currentThingId = thing.thingUuid
        const newThingId = uuid()
        thing.thingUuid = newThingId
        thing._dirty = true
        thing._added = true

        if(!Object.keys(config.ems.chargingPowerValues).includes(currentThingId)) {
            continue
        }

        const data = config.ems.chargingPowerValues[currentThingId]
        delete config.ems.chargingPowerValues[currentThingId]
        config.ems.chargingPowerValues[newThingId] = data
    }

    return config
}

function emitTrackingEvent(name) {
    window.dispatchEvent(new CustomEvent('tracking:event', { detail: name }))
}

// When a thing is removed we should ensure any EMS reference to it is also cleared
function clearEmsReferencesToThing(state, thingUuid) {
    if (state.emsData.chargingPowerValues) {
        delete state.emsData.chargingPowerValues[thingUuid]

        // If the last grid meter has been removed the EMS can not be active
        if (Object.keys(state.emsData.chargingPowerValues).length == 0) {
            state.emsData.isCmsActive = false
        }
    }
}
