export default {
    siteSetup: false,
    loading: false,
    generating: false,
    generationSuccess: false,
    lockedConfig: false,
    errors: [],
    messages: [],
    configurationUpdatePending: false,

    mode: 'configuration', // configuration | device-config

    site: {},
    supportedProductIds: [],

    supportedProtocols: [],
    deviceThings: [], // Legacy: Array of available 'things' for selected device
    deviceTemplateThings: {}, // New: Object of available 'things' for each device template
    protocolDefaults: {},
    emsCapabilities: {},
    slaveSpecifications: {},
    slaveOnlyDevices: [],

    manufacturers: [],
    deviceModels: [],
    deviceModelVersions: [],
    productModels: [],
    productModelVersions: [],
    deviceSpecifications: [],

    extensionManufacturers: [],
    extensionModels: [],
    extensionModelVersions: [],


    plannedDevices: [],
    plannedThings: [],
    plannedProducts: [],
    plannedExtensions: [],
    emsData: {},
    residualThings: [],

    savedConfig: {devices: [], things: [], cms: [], products: []}, // Current saved config, which will be loaded from API.
    generatedConfig: null,

    editingDevice: null,
    editingProduct: null,
    editingExtension: null,
    listOfPvs: [],

    deviceThingsBackup: {},

    experimental: false, // to show hide experimental features
    latestConfigTimestamp: null,
    currentLinkConfigurations: {}
}
