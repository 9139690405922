import moment from 'moment-es6';
import I18n from './i18n'
import convert from 'convert-units';

/**
 * Alias for convert units method.
 */
const convertToUnit = convert

/**
 * Format given value depending on order of magnitude and unit.
 *
 * The resulting string will include the magnitude (k or M) and
 * the converted value respectively.
 *
 * @param {number} value The value to format.
 * @param {string, null} unit The unit to add.
 * @param {number} precision The number of past decimal numbers.
 * @param {boolean} spacing Add spacing between value and unit.
 * @return {string} The formatted value with unit.
 */
const formatNumericValueWithUnit = (value, unit, precision = 1, spacing = true) => {
    value = parseFloat(value);

    if (isNaN(value)) { return '-'; }

    let magnitude = ''
    if (value >= 1000000 || value <= -1000000) {
        magnitude = 'M'
        value = value / 1000000
    }
    else if (value >= 1000 || value <= -1000) {
        magnitude = 'k'
        value = value / 1000
    }

    let components = (value >= 1000000 || value <= -1000000) ? [value.toPrecision(2)] : [value.toFixed(precision)]
    components.push(`${magnitude}${unit == null ? '' : unit}`)

    return (spacing ? components.join(' ') : components.join(''))
}

/**
 * Formats given value depending on type of value and given parameters unit, precision and spacing.
 *
 * @param value
 * @param unit
 * @param precision
 * @param spacing
 * @returns {string}
 */
const formatValueWithUnit = (value, unit, precision = 1, spacing = false) => {
    const values = [value].flat()

    return values.map((val) => {
        if (isNumeric(val)) {
            return formatNumericValueWithUnit(parseFloat(val), unit, precision, spacing)
        } else if (typeof val == 'boolean') {
            return formatBoolean(val)
        } else {
            return val || '-'
        }
    }).join(' | ')
}

/**
 * Find index of yAxis within yAxes with given key info.
 *
 * Each key and its corresponding info (e.g. datatype or unit) will be used
 * to search a matching yAxis within the array of axes.
 *
 * @param {array} yAxes An array of echarts yAxis options (should include `id`).
 * @param {string} key The key of the series to look for.
 * @param {object} info Additional infos for given key including `unit`.
 * @returns {number} The index of the yAxis found or 0.
 */
const findYAxisIndexForSeriesKey = (yAxes, key, info) => {
    let axisId = 'default'
    switch (info.unit) {
        case '%': axisId = 'percentage'; break;
        case 'V': axisId = 'voltage'; break;
        case 'A': axisId = 'ampere'; break;
        case 'W': axisId = 'power'; break;
        case 'Wh': axisId = 'energy'; break;
        case 'VA': axisId = 'apparent_power'; break;
        case 'VAr': axisId = 'reactive_power'; break;
        case '°C': axisId = 'temperature'; break;
        case 'Hz': axisId = 'frequency'; break;
        case 'h': axisId = 'operationTime'; break;
        default: axisId = 'default'
    }

    let index = yAxes.findIndex( axis => axis.id === axisId )
    return Math.max(0, index)
}

const findUnitForAxisIndex = (yAxes, axisIndex) => {
    let axis = yAxes[axisIndex]
    let unit
    switch (axis.id) {
        case 'percentage': unit = '%'; break;
        case 'voltage': unit = 'V'; break;
        case 'ampere': unit = 'A'; break;
        case 'power': unit = 'W'; break;
        case 'energy': unit = 'Wh'; break;
        case 'apparent_power': unit = 'VA'; break;
        case 'reactive_power': unit = 'VAr'; break;
        case 'temperature': unit = '°C'; break;
        case 'frequency': unit = 'Hz'; break;
        case 'operationTime': unit = 'h'; break;
        default: unit = ''
    }

    return unit
}

/**
 * Format a given datetime to the given format.
 *
 * @param {datetime} value The date value to format.
 * @param {string} format The format.
 * @returns {string} The formatted datetime.
 */
const formatDateTime = (value, format = "DD.MM.YYYY HH:mm") => {
    moment.locale(I18n.locale)
    return moment(value).format(format)
}

/**
 * Formats a boolean value to human readable representation.
 * @param value
 * @returns {string}
 */
const formatBoolean = (value) => {
    return value ? 'Ja' : 'Nein'
}

/**
 * Checks whether the given value is numeric or not.
 * @param value
 * @returns {boolean}
 */
const isNumeric = (value) => {
    if (typeof value == 'number') return true
    if (typeof value != 'string') return false
    return !isNaN(value) && !isNaN(parseFloat(value))
}

/**
 * Checks whether the given value is a blank value (null, undefined, '', '  ') or not.
 * @param value
 * @returns {boolean}
 */
const isBlank = (value) => {
    if (value === undefined) return true
    if (value === null) return true
    if (typeof value === 'string') return value.trim() === ''
    if (typeof value === 'object') return Object.keys(value).length === 0
    return false
}

/**
 * Remove object keys with null or undefined as value.
 * @param obj
 * @returns cleaned object
 */
const compactObject = (obj) => {
    if (obj === undefined) return undefined
    return Object.keys(obj)
        .filter(f => obj[f] !== null && obj[f] !== undefined)
        .reduce((r, i) => { r[i] = obj[i]; return r; }, {})
}

/**
 * Translate given key via I18n
 * @param scope
 * @param options for passing e.g. variables
 * @returns translated string
 */
export const t = (scope, options = {}) => {
    return I18n.t(scope, options)
}

/**
 * Returns the value depending on the currently used locale
 * @param enValue - value in en locale
 * @param deValue - value in de locale
 * @returns localized value
 */
const localizedValue = (enValue, deValue) => {
    return I18n.locale === 'en' ?  enValue : deValue
}

/**
 * A case insensitive string comparision
 * @param value1 - first value
 * @param value2 - second value
 * @returns true if both are the same
 */
export const caseInsensitiveCompare = (value1, value2) => {
    if (!value1 || !value2) { return false }
    if (value1 === value2) { return true }
    return value1.toLowerCase() === value2.toLowerCase();
}

/**
 * A case insensitive search for a needle in a haystack
 * @param haystack - array of values
 * @param needle - value to find
 * @returns true if haystack contains needle
 */
export const caseInsensitiveContains = (haystack, needle) => {
    if (!haystack || !needle) { return false }
    return haystack.findIndex( value => value.toLowerCase() === needle.toLowerCase()) > -1
}

/**
 * Convert a camel case string to a snake case string
 * @param str - the string in camel case
 * @returns the string in snake case
 */
const convertToSnakeCase = (str) => {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/**
 * Counts the decimal places for given number
 * @param value
 * @returns number of decimal places
 */
const countDecimals = (value) => {
    if(Math.floor(value) === value) {
        return 0;
    }

    return value.toString().split(".")[1].length || 0;
}

/**
 * Returns number with given precision.
 * @param num
 * @param precision
 * @returns {number}
 */
const numberWithPrecision = (num, precision = 2) => {
    const p = Math.pow(10, precision)
    return Math.round((num + Number.EPSILON) * p) / p
}

/**
 * Returns number for comparing two objects with a name attribute
 * to sort them in alphabetically order ignoring case.
 * @param a
 * @param b
 * @returns {number}
 */
const nameSorter = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0
};

/**
 * Truncates provided value according to specified cut off
 * points for a better UI readability
 * @param value - value to be truncated
 * @param magnitude - optional param to specify the value magnitude
 * @returns {number} - truncated value
 */
const truncateValue = (value, magnitude = 0) => {
    const cutOffPoint = magnitude === 0 ? 5 : 0.005
    const truncated_value = value < cutOffPoint && value > (cutOffPoint * -1) ? 0 : value

    return truncated_value % 1 === 0 ? parseInt(truncated_value) : truncated_value.toFixed(2)
}

const convertAmpereToPower = (value) => {
  let power = value * 230 * 3 / 1000
  power = Math.round(power * 100) / 100
  return power.toFixed(1)
}

const convertPowerToAmpere = (value) => {
  let current = value / (230 * 3) * 1000
  current = Math.round(current * 100 ) / 100
  return current.toFixed(2)
}

export default {
    formatNumericValueWithUnit,
    formatValueWithUnit,
    formatDateTime,
    findYAxisIndexForSeriesKey,
    findUnitForAxisIndex,
    formatBoolean,
    isNumeric,
    isBlank,
    t,
    localizedValue,
    caseInsensitiveCompare,
    caseInsensitiveContains,
    convertToSnakeCase,
    countDecimals,
    numberWithPrecision,
    compactObject,
    convertToUnit,
    nameSorter,
    truncateValue,
    convertPowerToAmpere,
    convertAmpereToPower
}
