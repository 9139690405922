import constants from './../../../globals/constants'

export default {
    isLoading: false,
    isError: false,
    isRedrawAnnotations: false,
    activeSiteSchema: null,
    activeThingId: null,
    activeSiteSchemaAnnotationId: null,
    position: null,
    scale: { scaleX: 1, scaleY: 1 },
    things: [],
    thingValues: [],
    activeMode: constants.MODE_VIEW,
    modes: [],
    siteSchemaAnnotations: []
}
